/**
 * Assets for Landingpages (Web)
 */

// styles
import '@aron/landingpage/main.scss';
import '@aron/search/css/search.scss';

// common js
import { commonWeb } from '@aron/common/main.common.js';

// content
import { TouchDetection } from '@fec/frontend/foundation/client/touch.js';
import { init as srfTeaserInit } from '@aron/landingpage/js/srf-rlp-teasers.js';
import { init as srfIframeEventTrackInit } from '@aron/common/srf-iframe-event-track.js';
import { init as srfTeaserMetaInit } from '@fec/frontend/elements/text/teaser-meta/teaser-meta.controller.js';
import { init as srfTeaserLinkInit } from '@fec/frontend/elements/links/teaser-ng-link/teaser-ng-link.js';
import { init as srfListCollectionInit } from '@fec/frontend/modules/collections/list/list.js';
import { init as emergencyBannerInit } from '@fec/frontend/modules/emergency-banner/emergency-banner.js';

import { init as srfSubnavHackInit } from '@aron/landingpage/js/srf-subnav-hack.js';
// Immediately fix the navi
srfSubnavHackInit();

$(document).ready(function () {
  // for css styles depending on touch capability
  if (TouchDetection.isTouchSupported()) {
    $('body').addClass('has-touch');
  } else {
    $('body').addClass('no-touch');
  }

  commonWeb();
  srfListCollectionInit();
  srfTeaserInit();
  srfIframeEventTrackInit();
  srfTeaserMetaInit();
  srfTeaserLinkInit();
  emergencyBannerInit();
});
